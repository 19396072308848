<template lang="pug">
LoadingTransition
</template>
<script>
  import LoadingTransition from '@/components/LoadingTransition.vue';
  import GET_USER_ALERT_EMAIL_DATA from '@/graphql/GetUserAlertEmailDataForRedirect.gql';
  import DISMISS_CAMPAIGN_ALERTS from '@/graphql/DismissCampaignAlerts.gql';

  export default {
    components: {
      LoadingTransition,
    },
    data() {
      return {
        emailId: '',
        action: '',
      };
    },
    async mounted() {
      this.emailId = this.$route.params?.emailId;
      this.action = this.$route.params?.action;

      try {
        const { alertType, campaignMongoId, databaseId, campaignId, variantId, storeAdminUrl } =
          await this.fetchEmailData();

        if (['edit', 'editAndDismiss'].includes(this.action)) {
          if (this.action === 'editAndDismiss') {
            await this.dismissUserAlert(campaignMongoId, alertType);
          }
          await this.delay();
          this.redirectToEditor(databaseId, campaignId, variantId);
        }

        if (['storeAndDismiss'].includes(this.action)) {
          await this.dismissUserAlert(campaignMongoId, alertType);
          await this.delay();
          this.redirectToUrl(storeAdminUrl);
        }
      } catch (err) {
        this.$router.replace({
          name: 'dashboard',
        });
        this.$notify({
          type: 'error',
          text: 'Something went wrong!',
          duration: 1000 * 15,
        });
      }
    },
    methods: {
      async fetchEmailData() {
        const {
          data: {
            getData: {
              success,
              alertType,
              campaignMongoId,
              editorPath: { databaseId, campaignId, variantId },
              storeAdminUrl,
            },
          },
        } = await this.$apollo.query({
          query: GET_USER_ALERT_EMAIL_DATA,
          variables: { emailId: this.emailId },
        });

        if (!success) return null;
        return { alertType, campaignMongoId, databaseId, campaignId, variantId, storeAdminUrl };
      },

      async dismissUserAlert(campaignId, alertType) {
        const types = [alertType];
        if (alertType === 'CampaignOutOfDiscountCodes') {
          types.push('CampaignAlmostOutOfDiscountCodes');
        }
        await this.$apollo.query({
          query: DISMISS_CAMPAIGN_ALERTS,
          variables: {
            campaignId,
            types,
          },
        });
      },

      redirectToEditor(databaseId, campaignId, variantId) {
        this.$router.replace({
          name: 'variant_edit',
          params: {
            userId: databaseId,
            campaignId,
            id: variantId,
          },
        });
      },

      redirectToUrl(url) {
        window.location = url;
      },

      delay(ms = 1000) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, ms);
        });
      },
    },
  };
</script>
